import { IonCheckbox, IonContent, IonInput, IonPage } from "@ionic/react";
import { useRef, useState } from "react";
import { CreateInvitation, InvitationPayload } from "../../lib";
import { ButtonRef, LinkButton, SubmitButton } from "../controls/shared/Buttons";
import { useHistory } from "react-router";
import { Routes } from "../../Routes";
import ContentHeader from "../layout/controls/ContentHeader";

export default function InvitationCreatePage() {
    const history = useHistory();
    const [payload, setPayload] = useState<InvitationPayload>({ paymentTypes: [ "openbanking" ] });
    const [submitting, setSubmitting] = useState(false);
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState(false);
    const submitButtonRef = useRef<ButtonRef>(null);

    // a partner can elect to deny cards to a merchant forever but the merchant can otherwise request cards during onboarding or subsequently in settings
    const denyCardsEnabled = payload.paymentTypes && payload.paymentTypes.includes("denycards");

    async function submit() {
        if (!payload.merchantName || !payload.merchantEmail) {
            submitButtonRef.current?.stopSpinning();
            return setError(true);
        }

        if (submitting) return;
        setSubmitting(true);
        setError(false);

        try {
            await CreateInvitation(payload);
            setComplete(true);
        }
        finally {
            setSubmitting(false);
        }
    }

    function toggleDenyCards() {
        if (payload.paymentTypes && payload.paymentTypes.includes("denycards")) {
            setPayload({ ...payload, paymentTypes: payload.paymentTypes.filter(type => type !== "denycards") });
        } else {
            setPayload({ ...payload, paymentTypes: [...payload.paymentTypes, "denycards"] });
        }
    }

    function content() {
        if (complete) return <>
            <h1>Confirmation</h1>
            <p>Your invitation has been successfully submitted.</p>
            <div style={{ display: "inline-flex", justifyContent: "end", width: "100%" }}>
                <LinkButton onClick={() => history.push(Routes.Home)} color="secondary">Return to Home Page</LinkButton>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <LinkButton onClick={() => setComplete(false)} color="secondary">Send Another Invitation</LinkButton>
            </div>
        </>;

        return <>
            <h1>Client Invitation</h1>
            <label>Client Name</label>
            <IonInput onIonChange={e => setPayload({ ...payload, merchantName: e.detail.value! })} />

            <label>Client Contact Email</label>
            <IonInput onIonChange={e => setPayload({ ...payload, merchantEmail: e.detail.value! })} />

            <label>Payment types that will be made available to the merchant</label><br />
            <IonCheckbox checked={true} /> Open Banking (mandatory)<br />

            <IonCheckbox checked={!denyCardsEnabled} onIonChange={toggleDenyCards} /> Card Payments
            {denyCardsEnabled && <div style={{ color: "gray", fontSize: "12px", marginTop: "-15px" }}>Card payments will never be offered to this client</div>}

            <div style={{ display: "inline-flex", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: "10px" }}>
                <div className="errorText">{error && "Please complete all fields"}</div>
                <SubmitButton text="Invite" onClick={submit} ref={submitButtonRef} />
            </div>
        </>;
    };

    return <>
        <IonPage>
            <IonContent className="ion-padding" fullscreen>
                <ContentHeader />
                <div style={{ lineHeight: "40px", maxWidth: "80%" }}>
                    {content()}
                </div>
            </IonContent>
        </IonPage>
    </>;
}